<template>
  <div class="swiper-duty">
    <swiper :options="swiperOption" ref="mySwiper">
      <swiper-slide>
        <p class="title">疫情防控</p>
        <div class="text">
          <p>在新冠肺炎疫情之战中，众望科技秉持科技向善,</p>
          <p>以互联网科技的力量，通过我们的技术和产品为全球战疫提供支持。</p>
        </div>
      </swiper-slide>
      <swiper-slide>
        <p class="title">大智慧环保</p>
        <div class="text">
          <p>大智慧环保分会隶属于四川省环境保护产业协会，成立于2020年6月30日。</p>
          <p>重点加强感知层智能环保设备研发和智慧层拓展数据运用等方面的建设，在相关细分领域深耕拓展。</p>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="swiper-button-prev" slot="button-prev">
      <i class="icon el-icon-arrow-left"></i>
    </div>
    <div class="swiper-button-next" slot="button-next">
      <i class="icon el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';

export default {
  name: 'SwiperDuty',
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        speed: 1000,
        // effect: 'fade',
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
          clickable: true, //允许分页点击跳转
          renderFraction: function (currentClass, totalClass) {
            console.log(currentClass);
            return (
              '<span class="' +
              currentClass +
              '"></span>' +
              '<span style="color:#fff">/</span>' +
              '<span class="' +
              totalClass +
              '"></span>'
            );
          }
        },
        // 前进后退按钮
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next'
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {
    const _this = this;
    console.log('this is current swiper instance object', this.swiper);

    // this.swiper.on('slideChangeTransitionStart', function () {
    //   // console.log(this.activeIndex);
    //   _this.onSlideStart(this.activeIndex);
    // });
  }
};
</script>

<style lang="less" scoped>
.swiper-duty {
  width: 100%;
  height: 100%;
}

.swiper-container {
  height: 100%;
}

.swiper-duty .swiper-container .swiper-slide {
  position: relative;
  width: 100%;
  // background: yellowgreen;
  color: #fff;
  font-size: 16px;
  text-align: center;
  background-image: url('~@/assets/images/swiper-duty-1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .title {
    z-index: 10;
    position: absolute;
    bottom: 120px;
    left: 46px;
    font-size: 24px;
    font-weight: bold;
    // color: #ffffff;
  }

  .text {
    z-index: 10;
    position: absolute;
    bottom: 62px;
    left: 46px;
    text-align: left;
  }

  &::after {
    content: '';
    position: absolute;
    left: 21px;
    bottom: 24px;
    width: 798px;
    height: 235px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.71) 100%);
    opacity: 0.62;
  }
}

.swiper-duty .swiper-container .swiper-slide:nth-child(odd) {
  background-image: url('~@/assets/images/swiper-duty-2.png');
}

.swiper-duty .swiper-container .swiper-slide:nth-child(even) {
  background-image: url('~@/assets/images/swiper-duty-1.png');
}

.swiper-button-prev,
.swiper-button-next {
  width: 60px;
  height: 120px;
  background: #000000;
  opacity: 0.2;
  top: 50%;
  left: 35px;
  transform: translateY(-50%);
  outline: none;
  text-align: center;
  line-height: 120px;
  border-radius: 2px;

  .icon {
    color: #fff;
    font-size: 20px;
  }

  &:hover {
    opacity: 0.4;
  }
}

.swiper-button-next {
  left: auto;
  right: 35px;
}

.swiper-pagination {
  bottom: 64px;
  text-align: right;
  padding-right: 50px;
  box-sizing: border-box;
}

/deep/ .swiper-pagination-current {
  font-size: 30px;
  color: #fff;
  font-weight: 700;
}

/deep/ .swiper-pagination-total {
  font-size: 14px;
  color: #fff;
}
</style>
