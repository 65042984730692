<template>
  <div class="wrapper">
    <!-- 轮播栏 -->
    <div class="swiper">
      <swiper-banner></swiper-banner>
    </div>
    <!-- 基本信息栏 -->
    <div class="info-contanier">
      <div class="info">
        <div class="info-box">
          <h3 class="title">成都众望智慧科技有限公司</h3>
          <p class="desc">成都众望智慧科技有限公司是一家以互联网为基础的平台公司</p>
          <p class="desc">通过技术大数据驱动生产性企业、工业园区、政府监管部门EHS的智能管理</p>
          <p class="desc">我们的使命是“为政府分忧；为园区把关；为企业减负</p>
          <div class="learn-morn">
            <span class="btn-text" @click="handleClickLearnMorn('introduction')">了解更多</span>
            <i class="el-icon el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- 卡片栏 -->
    <div class="card-contanier">
      <div class="card">
        <div class="list-wrapper">
          <div class="list-item">
            <img-card title="公司简介"></img-card>
          </div>
          <div class="list-item">
            <img-card title="企业文化" bg-name="img-card-culture.png"></img-card>
          </div>
          <div class="list-item">
            <img-card title="办公地点" bg-name="img-card-location.png"></img-card>
          </div>
        </div>
      </div>
    </div>
    <!-- 面向园区栏 -->
    <div class="face-contanier">
      <div class="face">
        <!-- 左侧导航 -->
        <div class="nav">
          <div class="nav-bar">
            <div class="nav-slider" :style="{ top: sliderTop }"></div>
          </div>

          <ul class="nav-list">
            <li
              class="nav-item"
              :class="{ active: activeIndex === 0 }"
              @click="handleClickNavItem(0)"
            >面向园区</li>
            <li
              class="nav-item"
              :class="{ active: activeIndex === 1 }"
              @click="handleClickNavItem(1)"
            >面向政府</li>
            <li
              class="nav-item"
              :class="{ active: activeIndex === 2 }"
              @click="handleClickNavItem(2)"
            >面向企业</li>
          </ul>
        </div>
        <!-- 右侧视图 -->
        <div class="view-area" @click="handleClickArea">
          <!-- 面向园区 -->
          <div
            class="view-item"
            :style="{ zIndex: activeIndex == 0 ? '9' : '7', opacity: activeIndex == 0 ? '1' : '0' }"
          >
            <div class="view-top">
              <div class="top-item">
                <view-card title="智慧园区" bgName="view-card-park.png"></view-card>
              </div>
              <div class="top-item">
                <view-card title="智慧水务" bgName="view-card-water.png"></view-card>
              </div>
            </div>
            <div class="view-bottom">
              <view-card title="智慧能耗" bgName="view-card-power.png"></view-card>
            </div>
          </div>
          <!-- 面向政府 -->
          <div
            class="view-item"
            :style="{ zIndex: activeIndex == 1 ? '9' : '7', opacity: activeIndex == 1 ? '1' : '0' }"
          >
            <div class="view-top">
              <div class="top-item">
                <view-card title="GIS网格" bgName="view-card-gard.png"></view-card>
              </div>
              <div class="top-item">
                <view-card title="智慧社区" bgName="view-card-community.png"></view-card>
              </div>
            </div>
            <div class="view-bottom">
              <view-card title="智慧党建" bgName="view-card-party.png"></view-card>
            </div>
          </div>
          <!-- 面向企业 -->
          <div
            class="view-item"
            :style="{ zIndex: activeIndex == 2 ? '9' : '7', opacity: activeIndex == 2 ? '1' : '0' }"
          >
            <div class="view-top">
              <div class="top-item">
                <view-card title="协同办公" bgName="view-card-admin.png"></view-card>
              </div>
              <div class="top-item">
                <view-card title="安全管家" bgName="view-card-safe.png"></view-card>
              </div>
            </div>
            <div class="view-top">
              <div class="top-item">
                <view-card title="环保管家" bgName="view-card-env.png"></view-card>
              </div>
              <div class="top-item">
                <view-card title="职业健康管家" bgName="view-card-health.png"></view-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 责任栏 -->
    <div class="duty-contanier">
      <div class="duty">
        <div class="duty-swiper">
          <swiper-duty></swiper-duty>
        </div>
        <div class="duty-box">
          <div class="duty-title">责任与信任</div>
          <div class="duty-desc">聚合微小善行，以科技让世界更美好</div>
          <div class="learn-morn">
            <span class="btn-text" @click="handleClickLearnMorn('responsibility')">了解更多</span>
            <i class="el-icon el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImgCard from './components/ImgCard.vue';
import ViewCard from './components/ViewCard.vue';
// import FuncButton from '@/components/funcButton.vue';
import SwiperBanner from './components/SwiperBanner.vue';
import SwiperDuty from './components/SwiperDuty.vue';

export default {
  components: {
    ImgCard,
    ViewCard,
    // FuncButton,
    SwiperBanner,
    SwiperDuty
  },
  data() {
    return {
      activeIndex: 0,
      sliderTop: '15px'
    };
  },
  mounted() {},
  methods: {
    // 点击 面向园区 等
    handleClickNavItem(index) {
      this.activeIndex = index;
      let str = '';

      switch (index) {
        case 0:
          str = '15px';
          break;
        case 1:
          str = '185px';
          break;
        case 2:
          str = '355px';
          break;
        default:
          break;
      }
      this.sliderTop = str;
    },
    // 点击 右侧视图
    handleClickArea() {
      // console.log(this.activeIndex);
      this.$router.push({
        path: 'profession',
        query: { params: this.activeIndex }
      });
    },
    //了解更多
    handleClickLearnMorn(path) {
      this.$router.push({
        path
      });
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  .swiper {
    height: 730px;
    width: 100vw;
  }

  .info-contanier {
    box-sizing: border-box;
    height: 630px;
    min-width: 1200px;
    background-color: #fff;
    background: url('~@/assets/images/info-wrapper-bg.png') center no-repeat;
    background-size: cover;

    .info {
      position: relative;
      width: 1200px;
      height: 100%;
      margin: 0 auto;

      .info-box {
        position: absolute;
        top: 200px;
        left: calc(~'50% + 10px');

        .title {
          margin-bottom: 33px;
          font-size: 24px;
          font-weight: bold;
          color: #333333;
        }

        .desc {
          margin-bottom: 16px;
          font-size: 16px;
          color: #666666;
        }

        .learn-morn {
          box-sizing: border-box;
          margin-top: 80px;
          width: 164px;
          height: 48px;
          border: 1px solid #1d7df8;
          border-radius: 30px;
          cursor: pointer;
          padding-left: 34px;
          padding-right: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #1d7df8;
          transition: all 0.2s ease-in-out;

          .btn-text {
            font-size: 18px;
            line-height: 24px;
          }

          .el-icon {
            font-size: 24px;
          }

          &:hover {
            background: #1d7df8;
            color: #fff;
          }
        }
      }
    }
  }

  .card-contanier {
    box-sizing: border-box;
    height: 630px;
    background: url('~@/assets/images/wrapper-bg-card.png') center no-repeat;
    background-size: cover;

    .card {
      position: relative;
      box-sizing: border-box;
      height: 100%;
      width: 1200px;
      margin: 0 auto;
      padding: 0 80px;

      .list-wrapper {
        width: 1040px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 120px;

        .list-item {
          width: 280px;
          height: 360px;
        }
      }
    }
  }

  .face-contanier {
    box-sizing: border-box;
    height: 1000px;

    .face {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      width: 1200px;
      height: 100%;
      padding: 80px 55px 80px 30px;
      margin: 0 auto;
      // background-color: skyblue;

      .nav {
        display: flex;
        padding-top: 38px;

        .nav-bar {
          position: relative;
          width: 9px;
          height: 430px;
          margin-right: 28px;
          background-color: #f4f4f4;
          border-radius: 9px;

          .nav-slider {
            position: absolute;
            // top: 15px;
            // top: 185px;
            // top: 355px;
            left: 0;
            height: 60px;
            width: 9px;
            border-radius: 9px;
            background-color: #1d7df8;
            transition: all 0.2s ease-out;
          }
        }

        .nav-list {
          display: flex;
          flex-direction: column;
          // justify-content: space-between;
          height: 370px;
          margin-top: 30px;

          .nav-item {
            display: flex;
            flex: 1;
            color: #666666;
            cursor: pointer;
            transition: all 0.2s ease-out;

            &:hover {
              font-size: 24px;
              color: #333;
            }

            &:nth-child(2) {
              align-items: center;
            }

            &:nth-child(3) {
              align-items: flex-end;
            }
          }

          .active {
            font-size: 24px;
            color: #333;
          }
        }
      }

      .view-area {
        position: relative;
        width: 840px;
        height: 840px;
        background-color: #fff;

        .view-item {
          position: absolute;
          top: 0;
          left: 0;
          transition: all 0.5s ease-out;
        }

        .view-top {
          width: 100%;
          height: 420px;

          .top-item {
            box-sizing: border-box;
            float: left;
            width: 420px;
            height: 100%;
            // border: 1px dashed #ddd;

            &::nth-child(2) {
              border-left: none;
            }
          }
        }

        .view-bottom {
          box-sizing: border-box;
          width: 100%;
          height: 420px;
          // border: 1px dashed #ddd;
          border-top: none;
        }
      }
    }
  }

  .duty-contanier {
    box-sizing: border-box;
    height: 760px;
    // background-color: pink;
    background: url('~@/assets/images/wrapper-bg-duty.png') center no-repeat;
    background-size: cover;

    .duty {
      position: relative;
      width: 1200px;
      height: 100%;
      margin: 0 auto;

      .duty-swiper {
        position: absolute;
        left: 0;
        top: 60px;
        z-index: 10;
        width: 838px;
        height: 568px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: rgba(146, 143, 143, 0.2) 0px 0px 12px;
      }

      .duty-box {
        position: absolute;
        right: 0;
        bottom: 68px;
        z-index: 8;
        box-sizing: border-box;
        width: 430px;
        height: 490px;
        background-color: #fff;
        padding-left: 136px;

        .duty-title {
          margin-top: 102px;
          margin-bottom: 24px;
          font-size: 24px;
          font-weight: bold;
          color: #333333;
        }

        .duty-desc {
          position: relative;
          color: #666666;

          &::after {
            content: '';
            position: absolute;
            top: 57px;
            left: 0;
            width: 100px;
            height: 3px;
            background-color: #1d7df8;
          }
        }

        .learn-morn {
          box-sizing: border-box;
          margin-top: 117px;
          width: 164px;
          height: 48px;
          border: 1px solid #1d7df8;
          border-radius: 30px;
          cursor: pointer;
          padding-left: 34px;
          padding-right: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #1d7df8;
          transition: all 0.2s ease-in-out;

          .btn-text {
            font-size: 18px;
            line-height: 24px;
          }

          .el-icon {
            font-size: 24px;
          }

          &:hover {
            background: #1d7df8;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
