<template>
  <div class="view-card" :style="{ backgroundImage: `url(${bgUrl})` }">
    <p class="card-btn">{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: 'ViewCard',
  props: {
    // 文字内容
    title: {
      type: String,
      required: true
    },
    // 放在 assets/images 目录下的所需图片 【文件名】
    bgName: {
      type: String,
      default: 'img-card-introduction.png'
    }
  },
  computed: {
    bgUrl() {
      const url = require(`../../../../assets/images/${this.bgName}`);
      return url;
    }
  }
};
</script>

<style lang="less" scoped>
.view-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease-out;

  .card-btn {
    position: relative;
    z-index: 10;
    width: 200px;
    height: 70px;
    background: rgba(255, 255, 255, 0.2);
    border: 2px solid #fff;
    border-radius: 36px;
    text-align: center;
    line-height: 70px;
    font-size: 24px;
    color: #ffffff;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      border-radius: 36px;
      background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
      transition: all 0.5s;
    }
  }

  &:hover {
    background-size: 120%;
  }

  &:left {
    background-size: 100%;
  }

  &:hover .card-btn {
    background: linear-gradient(215deg, #34affc 0%, #1d7df8 100%);
  }

  &:hover .card-btn::before {
    left: 100%;
  }
}
</style>
