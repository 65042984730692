<template>
  <div class="swiper-wrapper">
    <swiper :options="swiperOption" ref="mySwiper">
      <!-- 1 -->
      <swiper-slide>
        <h2 class="slide-title">
          <span>众望智慧 专注于互联网信息化服务</span>
        </h2>
        <p class="slide-info">
          <span>
            <span style="margin-right:20px">以客户为中心</span>
            <span style="margin-left:20px">创新驱动发展</span>
          </span>
        </p>
        <div
          class="btn"
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
          @click="handleClickContact"
        >联系我们</div>
      </swiper-slide>
      <!-- 2 -->
      <swiper-slide>
        <h2 class="slide-title">
          <span>众望智慧管理平台</span>
        </h2>
        <p class="slide-info">
          <span>EHS+水气土协同预警+协同办公+N</span>
        </p>
        <div
          class="btn"
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
          @click="handleClickContact"
        >联系我们</div>
      </swiper-slide>
      <!-- 3 -->
      <swiper-slide>
        <h2 class="slide-title">
          <span>众志成城 抗击疫情</span>
        </h2>
        <p class="slide-info">
          <span>严防严控、群防群控、疫情常态化快速响应</span>
        </p>
        <div
          class="btn"
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
          @click="handleClickContact"
        >联系我们</div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';

export default {
  name: 'SwiperBanner',
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        // loop: true,
        speed: 800,
        effect: 'fade',
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true, //允许分页点击跳转
          bulletClass: 'my-bullet', //需设置.my-bullet样式
          bulletActiveClass: 'my-bullet-active'
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {
    const _this = this;
    console.log('this is current swiper instance object', this.swiper);

    const TITLE_LIST = document.querySelectorAll('.slide-title');
    const TITLE_SPAN = TITLE_LIST[0].querySelector('span');
    TITLE_SPAN.style.backgroundPositionX = '0%';

    const SLIDE_INFO_LIST = document.querySelectorAll('.slide-info');
    const SLIDE_INFO_SPAN = SLIDE_INFO_LIST[0].querySelector('span');
    SLIDE_INFO_SPAN.style.backgroundPositionX = '0%';

    const BTN_LIST = document.querySelectorAll('.btn');
    const activeBtn = BTN_LIST[0];
    activeBtn.style.opacity = '1';

    this.swiper.on('slideChangeTransitionStart', function () {
      // console.log(this.activeIndex);
      _this.onSlideStart(this.activeIndex);
    });
    this.swiper.on('slideChangeTransitionEnd', function () {
      // console.log(this.activeIndex);
      _this.onSlideEnd(this.activeIndex);
    });
  },
  methods: {
    // 滑动开始
    onSlideStart(index) {
      let i = index ? index - 1 : 2;

      const TITLE_LIST = document.querySelectorAll('.slide-title');
      const TITLE_SPAN = TITLE_LIST[i].querySelector('span');

      const SLIDE_INFO_LIST = document.querySelectorAll('.slide-info');
      const SLIDE_INFO_SPAN = SLIDE_INFO_LIST[i].querySelector('span');

      const BTN_LIST = document.querySelectorAll('.btn');
      const activeBtn = BTN_LIST[i];

      TITLE_SPAN.style.backgroundPositionX = '100%';
      SLIDE_INFO_SPAN.style.backgroundPositionX = '100%';

      activeBtn.style.opacity = '0';
    },
    // 滑动结束
    onSlideEnd(index) {
      const TITLE_LIST = document.querySelectorAll('.slide-title');
      const TITLE_SPAN = TITLE_LIST[index].querySelector('span');

      const SLIDE_INFO_LIST = document.querySelectorAll('.slide-info');
      const SLIDE_INFO_SPAN = SLIDE_INFO_LIST[index].querySelector('span');

      const BTN_LIST = document.querySelectorAll('.btn');
      const activeBtn = BTN_LIST[index];

      TITLE_SPAN.style.backgroundPositionX = '0%';
      SLIDE_INFO_SPAN.style.backgroundPositionX = '0%';

      activeBtn.style.opacity = '1';
    },
    // 鼠标移入按钮
    handleMouseEnter() {
      this.swiper.autoplay.stop();
    },
    // 鼠标移除
    handleMouseLeave() {
      this.swiper.autoplay.start();
    },
    // 点击联系我们
    handleClickContact() {
      this.$router.push({
        path: 'contact',
        query: { params: this.$Base64.encode('platform') }
      });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .my-bullet {
  display: inline-block;
  width: 20px;
  height: 5px;
  margin: 0 5px;
  background: #ffffff;
  border-radius: 3px;
}

/deep/ .my-bullet-active {
  width: 40px;
  background: #1d7df8;
}

.swiper-wrapper {
  width: 100%;
  height: 100%;
}

.swiper-container {
  width: 100%;
}

.swiper-wrapper .swiper-container .swiper-slide {
  width: 100%;
  background-image: url('~@/assets/images/swiper-banner-3.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-wrapper .swiper-container .swiper-slide:nth-child(2) {
  background-image: url('~@/assets/images/swiper-banner-2.png');
}

.swiper-wrapper .swiper-container .swiper-slide:nth-child(3) {
  background-image: url('~@/assets/images/swiper-banner-1.png');
}

// .swiper-wrapper .swiper-container .swiper-slide:nth-child(3) {
//   background-image: url('~@/assets/images/swiper-banner-2.png');
// }

// .swiper-wrapper .swiper-container .swiper-slide:nth-child(1),
// .swiper-wrapper .swiper-container .swiper-slide:nth-child(4) {
//   background-image: url('~@/assets/images/swiper-banner-1.png');
// }

.slide-title {
  // width: 734px;
  // height: 64px;
  margin: 0 auto;
  margin-top: 240px;
  margin-bottom: 23px;
  text-align: center;
  line-height: 64px;
  font-size: 48px;
  font-weight: bold;
  color: transparent;

  span {
    background-image: linear-gradient(
      75deg,
      #fff 0%,
      #fff 33.33%,
      rgba(255, 255, 255, 0) 66.67%,
      rgba(255, 255, 255, 0) 100%
    );
    background-size: 300% 100%;
    background-position-x: 100%;
    background-clip: text;
    transition: background-position-x 1.5s ease-in-out;
  }
}

.slide-info {
  text-align: center;
  font-size: 24px;
  color: transparent;
  margin-bottom: 50px;

  span {
    background-image: linear-gradient(
      75deg,
      #fff 0%,
      #fff 33.33%,
      rgba(255, 255, 255, 0) 66.67%,
      rgba(255, 255, 255, 0) 100%
    );
    background-size: 300% 100%;
    background-position-x: 100%;
    background-clip: text;
    transition: background-position-x 1s ease-in-out;
    transition-delay: 1s;
  }
}

.btn {
  margin: 0 auto;
  width: 210px;
  height: 60px;
  background: linear-gradient(270deg, #43baff 0%, #1d7df8 100%);
  border-radius: 30px;
  text-align: center;
  line-height: 60px;
  color: #fff;
  font-size: 24px;
  transition: all 1s ease-in-out;
  transition-delay: 1.5s;

  opacity: 0;
  cursor: pointer;
}
</style>
